export const transformProducts = (products = []) => {
  if (products?.length) {
    return products.map((product) => ({
      ...product,
      ...(product.category && { category: product.category?.trim() }),
      ...(product.position && { position: Number(product.position) }),
      ...(product.price && { price: String(product.price) }),
      ...(product.dimension4 && { dimension4: Number(product.dimension4 || '') }),
    }));
  }
  return products;
};

export const transformFilter = ({
  coupon_amount,
  fm = '',
  isAccessible = '',
  isUpsell = '',
  pay_type_option,
  res_id,
  value,
  ...rest
}) => ({
  coupon_amount: coupon_amount?.toString(),
  fm: fm.toString(),
  isAccessible: isAccessible.toString(),
  isUpsell: isUpsell.toString(),
  pay_type_option: pay_type_option?.toString()?.trim(),
  res_id: res_id?.toString(),
  value: value?.toString(),
  ...rest,
});

export const mapAllFilters = (allFilters) => {
  const {
    flowType: flow_type,
    fm: FM,
    isAccessible: accessibility,
    isUpsell: upsell,
    sailorCount: sailors_number,
    ...rest
  } = allFilters;

  return {
    accessibility,
    flow_type,
    FM,
    sailors_number,
    upsell,
    ...rest,
  };
};

const PAYMENT_METHODS_NAMES_MAP = {
  applepay: 'Apple Pay',
  card: 'Credit Card',
  default: 'Credit Card',
  paypal: 'PayPal',
  saved_card: 'Credit Card',
};
export const transformPaymentMethod = (joinedLowerCaseWords) =>
  PAYMENT_METHODS_NAMES_MAP?.[joinedLowerCaseWords] || PAYMENT_METHODS_NAMES_MAP.default;
