import type { AuthFlow } from '@/containers/VoyagePlanner/Summary/CheckoutDialog/AuthBlock/AuthModal';
import type { onAddonToggleArgs } from '@/containers/VoyagePlanner/Summary/SailingDetailsBlock/Addons';
import type { AccessKey } from '@/ducks/accessKeys/type';
import type * as tagmanagerTypes from '@/tagmanager/types';

import { trackData } from '@/tagmanager/dataLayer';
import {
  trackButtonClick,
  trackCheckboxToggle,
  trackIconClick,
  trackLinkClick,
  trackPanelToggle,
  trackPopupState,
} from '@/tagmanager/event/types/common';

import constants from '../../constants';
import { createTracker } from '../../tracker';
import builders from '../builders';

const trackEvent = createTracker({ event: constants.EVENTS.GTMevent });

export const trackModifyCabinClick = (label: string) => {
  trackButtonClick({ labels: [label] });
};

export const trackAddonPlusClick = (label?: string) => trackIconClick({ labels: ['plus', label] });

export const trackAddonAddClick = ({ flyout, label }: tagmanagerTypes.LabelInFlyoutForTracking) => {
  const buildedLabel = builders.buildLabel([flyout, constants.FLYOUTS.FLYOUT], { delimiter: '-' });
  trackButtonClick({ labels: [label], module: buildedLabel });
};

export const trackAddonFlyoutClose = ({ flyout }: tagmanagerTypes.LabelInFlyoutForTracking) => {
  const buildedLabel = builders.buildLabel([flyout, constants.FLYOUTS.FLYOUT], { delimiter: '-' });

  trackPopupState({ isOpen: false, module: buildedLabel });
};

export const trackApplyDiscountClick = (label: string) => {
  trackButtonClick({ labels: [`${label}-discount`] });
};

export const trackOpenFlyoutFacilityBlock = ({ flyout, label }: tagmanagerTypes.LabelInFlyoutForTracking) => {
  const buildedLabel = builders.buildLabel([flyout, constants.FLYOUTS.FLYOUT], { delimiter: '-' });

  trackIconClick({ labels: ['plus', label], module: buildedLabel });
};

export const trackAuthSignInUpClick = ({ label }: tagmanagerTypes.LabelForTracking) => {
  trackButtonClick({ labels: [label] });
};

export const trackAuthSignInUp = ({
  loginType,
  socialProvider,
  userId,
}: {
  loginType?: AuthFlow;
  socialProvider?: string;
  userId: string;
}) => {
  const transformedLoginType = loginType === 'signup' ? 'create' : 'sign-in';
  const transformedSocialType = socialProvider || 'email';

  trackEvent({
    action: transformedLoginType,
    label: transformedSocialType,
    module: constants.FLYOUTS.ACCOUNTS_WITH_BENEFITS,
    sailor_id: userId,
  });
};

export const trackAuthManualModeClick = (label: string) => {
  trackLinkClick({ labels: [label] });
};

export const trackMobileOptInToggle = (state: boolean) => {
  trackCheckboxToggle({ labels: [constants.EVENT_LABELS.MOBILE_OPT_IN], state });
};

export const trackEmailOptInRadioToggle = (state: boolean) => {
  trackCheckboxToggle({
    labels: [state ? constants.EVENT_LABELS.EMAIL_OPT_IN_RADIO_YES : constants.EVENT_LABELS.EMAIL_OPT_IN_RADIO_NO],
    state,
  });
};

export const trackToggleFareBreakdown = (args: tagmanagerTypes.LabelOnTogglerForTracking) => {
  const { state } = args;
  const label = constants.EVENT_LABELS.FARE_BREAKDOWN;

  trackPanelToggle({ labels: [label], state });
};

export const trackAuthSignInUpPopupStateToggle = (state: boolean) => {
  if (!state) {
    return;
  }

  trackPopupState({ isOpen: state, module: constants.FLYOUTS.ACCOUNTS_WITH_BENEFITS });
};

export const trackAccessKeyAutoApplied = (accessKey: AccessKey) => {
  const buildedLabel = builders.buildLabel([constants.EVENT_LABELS.ACCESS_KEY, accessKey], { lowerCase: false });

  trackEvent({ action: constants.EVENT_ACTIONS.AUTO_APPLIED, label: buildedLabel });
};

export const trackAccessKeyAutoAppliedRaw = () => {
  trackData({ access_key_auto_applied: 'true' });
};

export const trackAccessKeyFieldClick = () => {
  trackEvent({ action: constants.EVENT_ACTIONS.FIELD_CLICK, label: constants.EVENT_LABELS.ACCESS_KEY });
};

export const trackAccessKeyValidateClick = (label: string) => {
  trackLinkClick({ labels: [label] });
};

export const trackAccessKeyError = ({ label }: tagmanagerTypes.LabelForTracking) => {
  const buildedLabel = builders.buildLabel([constants.EVENT_LABELS.ACCESS_KEY, label]);
  trackEvent({ action: constants.EVENT_ACTIONS.ERROR, label: buildedLabel });
};

export const trackAccessKeyApplied = () => {
  trackEvent({ action: constants.EVENT_ACTIONS.FORM_COMPLETION, label: constants.EVENT_LABELS.ACCESS_KEY_APPLIED });
};

export const trackAccessKeyClear = ({ label }: tagmanagerTypes.LabelForTracking) => {
  trackIconClick({ labels: [label] });
};

export const trackChangeSailDateClick = () => {
  trackLinkClick({ labels: [constants.EVENT_LABELS.CHANGE_SAIL_DATE] });
};

export const trackCheckoutClick = (label: string) => {
  trackButtonClick({ labels: [label] });
};

export const trackVoyageProtectionChange = ({ flyoutHeading, label, source, state }: onAddonToggleArgs) => {
  if (!state) {
    return;
  }

  if (source === 'CARD') {
    trackAddonPlusClick(label);
  }
  if (source === 'FLYOUT') {
    trackAddonAddClick({ flyout: flyoutHeading, label });
  }
};

export const trackToPaymentCTAClick = ({ label }: tagmanagerTypes.LabelForTracking) => {
  const ctaLabel = builders.buildCtaLabel(label);
  trackButtonClick({ labels: [ctaLabel] });
};

export const trackAddNewSailorClick = () => {
  trackIconClick({ labels: [constants.EVENT_LABELS.SUMMARY.ADD_NEW_SAILOR] });
};

export const trackSaveNewSailorClick = () => {
  trackButtonClick({ labels: [constants.EVENT_LABELS.SUMMARY.ADD_SAILOR_TO_VOYAGE] });
};

export const trackEditNewSailorClick = () => {
  trackIconClick({ labels: [constants.EVENT_LABELS.SUMMARY.EDIT] });
};
