import { createSelector } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';

import { getDefaultFilters } from '@/ducks/filtersOptions/selectors';
import { selectFilteredPackages, selectMainPackages } from '@/ducks/pages/chooseVoyage/selectors';
import {
  getSailingPriceRangeForSailorPerNight,
  getSailingsPriceRange,
  type TPriceRangeData,
} from '@/helpers/data/mappers/amount';
import { FiltersPriceType } from '@/infra/types/common/filters';

import { selectCabins, selectPriceType, selectSailors } from './selectors';

export const selectDefaultMaxPrice = createSelector(
  getDefaultFilters,
  (defaultFilters) => defaultFilters.maxPrice || 0, // todo: consider filtered packages
);

export const selectDefaultPriceRange = createSelector(
  getDefaultFilters,
  selectCabins,
  selectSailors,
  selectPriceType,
  selectMainPackages,
  selectFilteredPackages,
  (defaultFilters, cabins, sailors, priceType, packages, filteredPackages) => {
    const actualPackages = isEmpty(filteredPackages) ? packages : filteredPackages;
    let data;
    if (priceType === FiltersPriceType.sailorPerNight) {
      data = getSailingPriceRangeForSailorPerNight(actualPackages, { sailors }) as TPriceRangeData;
    } else {
      data = getSailingsPriceRange(actualPackages, { cabins, priceType, sailors }) as TPriceRangeData;
    }
    const { maxPrice, minPrice } = data || {};

    return {
      maxPrice: maxPrice || defaultFilters.maxPrice || 0,
      minPrice: minPrice || defaultFilters.minPrice || 0,
    };
  },
);
